"use client"
import { useSuspenseQuery } from "@apollo/experimental-nextjs-app-support/ssr"
import Skeleton from "@mui/material/Skeleton"
import { useTheme } from "@mui/material/styles"
import map from "lodash-es/map"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import {
  ContentContainer,
  ContentContainerBackgroundStyle,
} from "../ContentContainer"

import styles from "./LatestNewsTeaserRow.module.scss"

import { Animated } from "@/components/common/animated"
import { Button } from "@/components/common/button"
import { Grid, GridRow } from "@/components/common/grid"
import { Headline } from "@/components/common/headline"

import { create } from "@/helpers/bem"
import { resolveDarkModeAsset } from "@/helpers/theme"
import { trackPageChange } from "@/helpers/tracking"
import { GET_LATEST_POSTS } from "@/queries/blog/posts"
import {
  LatestPostsQuery,
  ArticleEntity,
  LatestNewsTeaserRowFragmentFragment,
} from "@/types/generated/strapi/graphql"

const bem = create(styles, "LatestNewsTeaserRow")

const LazyPostTeaser = dynamic(
  () =>
    import("@/components/common/post-teaser").then(({ PostTeaser }) => ({
      default: PostTeaser,
    })),
  {
    loading: () => (
      <Skeleton
        className={bem("skeleton")}
        height={400}
        variant="rectangular"
      />
    ),
  },
)

export type LatestNewsTeaserRowProps = LatestNewsTeaserRowFragmentFragment

export const LatestNewsTeaserRow: FC<LatestNewsTeaserRowProps> = ({
  spacing,
  backgroundStyle,
  latestNewsTitle,
  titleOptions,
  subTitle,
  subTitleOptions,
}) => {
  const path = usePathname()
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language
  const { data } = useSuspenseQuery<LatestPostsQuery>(GET_LATEST_POSTS, {
    variables: { locale: currentLocale },
  })
  return (
    <ContentContainer
      backgroundStyle={backgroundStyle as ContentContainerBackgroundStyle}
    >
      <div className={bem("header")}>
        {subTitle && (
          <Headline
            highlight
            align="center"
            className={bem("sub_headline", undefined)}
            size="sm"
            type="h3"
            variant="primary"
            {...subTitleOptions}
          >
            {subTitle}
          </Headline>
        )}
        {latestNewsTitle && (
          <Headline
            align="center"
            className={bem("headline", undefined)}
            size="lg"
            type="h2"
            {...titleOptions}
          >
            {latestNewsTitle}
          </Headline>
        )}
      </div>
      <Grid>
        {map(data.articles?.data, ({ id, attributes }: ArticleEntity) => {
          const href =
            `blog/${attributes?.category.data?.attributes?.slug}/${attributes?.slug}` ??
            "#"
          return (
            <GridRow key={id} md={4} spacing={spacing ?? 2} xs={12}>
              <LazyPostTeaser
                category={attributes?.category.data?.attributes?.name}
                className={bem("teaser")}
                content={attributes?.description ?? ""}
                image={
                  attributes?.cover
                    ? {
                        src:
                          resolveDarkModeAsset(theme, attributes?.cover) ?? "",
                        alt:
                          attributes?.cover?.data?.attributes
                            ?.alternativeText ?? "",
                      }
                    : undefined
                }
                redirect={{
                  href,
                  label: t("pages.components.latestNewsTeaserRow.link"),
                }}
                title={{
                  content: attributes?.title ?? "",
                }}
                onClick={() =>
                  trackPageChange("latest_news_teaser", path, href, "article")
                }
              />
            </GridRow>
          )
        })}
      </Grid>
      <Grid>
        <GridRow margin="0 auto" md={4} xs={12}>
          <Animated className={bem("button")}>
            <Button
              fullWidth
              title={t("pages.components.latestNewsTeaserRow.button")}
              variant="outlined"
              redirect={{
                href: `/${currentLocale}/blog/`,
              }}
              onClick={() =>
                trackPageChange(
                  "latest_news_teaser",
                  path,
                  `/${currentLocale}/blog/`,
                  "blog",
                )
              }
            />
          </Animated>
        </GridRow>
      </Grid>
    </ContentContainer>
  )
}
