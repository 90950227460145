import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/@apollo+client-react-streaming@0.11.2_@apollo+client@3.10.8_@types+react@18.3.3_graphql-ws@5._credyggk7uk5hz3idyxrqddafq/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/CarouselScreenshots/CarouselScreenshots.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/ContentArea/ContentArea.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/ContentContainer/ContentContainer.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/FAQs/FAQ.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/FAQs/FAQs.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/FeatureCampaign/FeatureCampaign.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/FeatureCampaign/FeatureCampaignContent.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/ImageText/ImageText.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/LatestNewsTeaserRow/LatestNewsTeaserRow.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/TeaserFeatures/TeaserFeatures.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/TeaserRow/TeaserRow.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/app/[lang]/components/VideoBanner/VideoBanner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/components/common/headline/Headline.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mymatey-website/mymatey-website/frontend/src/components/common/rich-text/RichText.tsx");
