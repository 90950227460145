"use client"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import type { FC, ReactNode, KeyboardEvent, CSSProperties } from "react"
import React, {
  Children,
  Ref,
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react"

import styles from "./Carousel.module.scss"
import type { Direction } from "./Carousel.services"
import {
  NEXT,
  PREV,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleMouseDown,
  handleMouseUp,
} from "./Carousel.services"

import { create } from "@/helpers/bem"

const bem = create(styles, "Carousel")

export type CarouselProps = {
  ref?: Ref<CarouselRef>
  autoplay?: number
  translateX?: string
  itemStyle?: CSSProperties
  itemsToShow?: number
  className?: string
  classNameItem?: string
  classNameDots?: string
  children: ReactNode
}

export type CarouselRef = {
  slidePrev: () => void
  slideNext: () => void
  carouselContainer: HTMLDivElement | null
}

export const Carousel: FC<CarouselProps> = ({
  ref,
  autoplay,
  itemsToShow = 1,
  className,
  classNameItem,
  classNameDots,
  children,
}) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const carouselContainerRef = useRef<HTMLDivElement>(null)
  const touchStartX = useRef<number>(0)
  const touchEndX = useRef<number>(0)
  const mouseStartX = useRef<number>(0)
  const mouseEndX = useRef<number>(0)

  const [currentIndex, setCurrentIndex] = useState<number>(itemsToShow)
  const [mouseActive, setMouseActive] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState<boolean>(!!autoplay)
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const childrenArray = Children.toArray(children)
  const [items, setItems] = useState<ReactNode[]>([])

  const numItems = childrenArray.length

  useEffect(() => {
    const newItems = [
      ...childrenArray.slice(-itemsToShow),
      ...childrenArray,
      ...childrenArray.slice(0, itemsToShow),
    ]

    if (!isEqual(newItems, items)) {
      setItems(newItems)
      setCurrentIndex(itemsToShow)
    }
  }, [items, childrenArray, itemsToShow])

  const slide = useCallback(
    (direction: Direction) => {
      if (isTransitioning) return

      setIsTransitioning(true)
      let newIndex = currentIndex

      if (direction === NEXT) {
        newIndex = currentIndex + 1
      } else if (direction === PREV) {
        newIndex = currentIndex - 1
      }

      setCurrentIndex(newIndex)

      setTimeout(() => {
        if (newIndex >= numItems + itemsToShow) {
          setCurrentIndex(itemsToShow)
          setIsTransitioning(false)
        } else if (newIndex < itemsToShow) {
          setCurrentIndex(numItems)
          setIsTransitioning(false)
        } else {
          setIsTransitioning(false)
        }
      }, 300)
    },
    [isTransitioning, currentIndex, itemsToShow, numItems],
  )

  useEffect(() => {
    if (!autoplay) return
    let interval
    if (isPlaying) {
      interval = setInterval(() => {
        slide(NEXT)
      }, autoplay)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [slide, isPlaying, autoplay])

  const slideToIndex = useCallback(
    (index: number) => {
      if (isTransitioning) return
      setIsTransitioning(true)

      const targetIndex = index + itemsToShow

      setCurrentIndex(targetIndex)

      setTimeout(() => {
        if (targetIndex >= numItems + itemsToShow) {
          setCurrentIndex(targetIndex - numItems)
          setIsTransitioning(false)
        } else if (targetIndex < itemsToShow) {
          setCurrentIndex(numItems + targetIndex)
          setIsTransitioning(false)
        } else {
          setIsTransitioning(false)
        }
      }, 300)
    },
    [isTransitioning, itemsToShow, numItems],
  )

  useImperativeHandle(ref, () => ({
    slidePrev: () => slide(PREV),
    slideNext: () => slide(NEXT),
    carouselContainer: carouselContainerRef.current,
  }))

  const handleMouseEnter = useCallback(() => setIsPlaying(false), [])
  const handleMouseLeave = useCallback(() => setIsPlaying(true), [])

  const getTransformValue = () => {
    const offset = -currentIndex * (100 / itemsToShow)
    return `translateX(${offset}%)`
  }

  const renderDots = useCallback(
    () =>
      numItems > 0 && (
        <span className={bem("dots", undefined, classNameDots)}>
          {childrenArray.map((_, i) => {
            const isActive = isEqual(
              i,
              (currentIndex - itemsToShow + numItems) % numItems,
            )
            return (
              <i
                key={`carousel-dot-${i}`}
                tabIndex={0}
                className={bem("dots__dot", {
                  [mode]: true,
                  "is-active": isActive,
                  [`is-active--${mode}`]: isActive,
                })}
                onClick={() => slideToIndex(i)}
                onKeyDown={(e: KeyboardEvent) =>
                  e.key === "Enter" && slideToIndex(i)
                }
              ></i>
            )
          })}
        </span>
      ),
    [
      mode,
      numItems,
      childrenArray,
      currentIndex,
      classNameDots,
      itemsToShow,
      slideToIndex,
    ],
  )

  if (isEqual(numItems, 0)) {
    return null
  }

  return (
    <div
      ref={carouselContainerRef}
      className={bem(
        undefined,
        {
          "is-active": mouseActive,
        },
        className,
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchEnd={() => handleTouchEnd(touchStartX, touchEndX, slide)}
      onTouchMove={(e) => handleTouchMove(e, touchEndX)}
      onTouchStart={(e) => handleTouchStart(e, touchStartX)}
      onMouseDown={(e) => {
        handleMouseDown(e, mouseStartX)
        setMouseActive(true)
      }}
      onMouseUp={(e) => {
        handleMouseUp(e, mouseStartX, mouseEndX, slide)
        setMouseActive(false)
      }}
    >
      <div
        className={bem("wrapper")}
        style={{
          transform: getTransformValue(),
          transition: isTransitioning ? "transform 0.3s ease-in-out" : "none",
        }}
      >
        {map(items, (child, i) => (
          <div
            key={i}
            className={bem("item", undefined, classNameItem)}
            style={{
              width: `${100 / itemsToShow}%`,
            }}
          >
            {child}
          </div>
        ))}
      </div>
      {renderDots()}
    </div>
  )
}

Carousel.displayName = "Carousel"
