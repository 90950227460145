"use client"
import { Skeleton } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import size from "lodash-es/size"
import dynamic from "next/dynamic"
import {
  FC,
  ReactNode,
  VideoHTMLAttributes,
  SourceHTMLAttributes,
  isValidElement,
  useCallback,
} from "react"

import { AppStoreButtons, AppStoreButtonType } from "../app-store-buttons"
import { Grid, GridRow } from "../grid"
import { Headline } from "../headline"
import type { NextImageProps } from "../image"
import { NextImage } from "../image"

const LazyNextImage = dynamic(
  () =>
    import("@/components/common/image").then(({ NextImage }) => ({
      default: NextImage,
    })),
  {
    loading: () => <Skeleton height={400} variant="rectangular" />,
  },
)

import styles from "./VideoBanner.module.scss"

import { create } from "@/helpers/bem"

import PhoneDark from "@/public/images/video_banner_phone_dark.webp"
import PhoneLight from "@/public/images/video_banner_phone_light.webp"

const bem = create(styles, "VideoBanner")

export type VideoBannerLocale = "en" | "de"

export type VideoBannerSrc = {
  key?: string
} & SourceHTMLAttributes<HTMLSourceElement>

export type VideoBannerScreenshot = NextImageProps

export type VideoBannerProps = {
  locale?: VideoBannerLocale
  className?: string
  videoPosterSrc?: string
  iframe?: ReactNode
  videoSrc?: VideoBannerSrc[]
  videoOptions?: VideoHTMLAttributes<HTMLVideoElement>
  headline: string
  content?: string
  appleButtonUrl?: string
  playButtonUrl?: string
  screenshot: VideoBannerScreenshot | ReactNode
  onClickButton?: (type: AppStoreButtonType) => void
}

export const VideoBanner: FC<VideoBannerProps> = ({
  locale,
  className,
  videoPosterSrc,
  iframe,
  videoSrc,
  videoOptions,
  headline,
  content,
  appleButtonUrl,
  playButtonUrl,
  screenshot,
  onClickButton,
}) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const themeModifier = {
    [mode]: true,
  }
  const isDark = isEqual(mode, "dark")
  const Phone = {
    light: PhoneLight,
    dark: PhoneDark,
  }
  const renderScreenshot = useCallback(() => {
    if (isValidElement(screenshot)) {
      return screenshot
    }
    return (
      <LazyNextImage
        {...(screenshot as VideoBannerScreenshot)}
        fill
        className={bem("image__screenshot")}
        loading="eager"
      />
    )
  }, [screenshot])
  return (
    <div className={bem(undefined, undefined, className)}>
      <div className={bem("video__container", themeModifier)}>
        {iframe ? (
          iframe
        ) : (
          <video
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            poster={videoPosterSrc}
            {...videoOptions}
            className={bem("video", themeModifier, videoOptions?.className)}
          >
            {!isEqual(size(videoSrc), 0) &&
              map(videoSrc, (attr) => <source {...attr} key={attr.key} />)}
          </video>
        )}
      </div>
      <div className={bem("content__wrapper")}>
        <Grid spacing={0}>
          <GridRow
            alignItems="flex-start"
            className={bem("col")}
            md={6}
            xs={12}
          >
            <div className={bem("content")}>
              <Headline
                highlight
                isRichtext
                size="xxl"
                type="h1"
                variant={isDark ? "primary" : "white"}
              >
                {headline}
              </Headline>
              {content && (
                <Headline
                  highlight
                  isRichtext
                  className={bem("desc")}
                  size="md"
                  type="h2"
                  variant="white"
                >
                  {content}
                </Headline>
              )}
              <AppStoreButtons
                appleButtonUrl={appleButtonUrl}
                className={bem("buttons")}
                locale={locale}
                playButtonUrl={playButtonUrl}
                onClick={onClickButton}
              />
            </div>
          </GridRow>
          <GridRow
            alignItems="center"
            className={bem("col")}
            justifyContent="flex-end"
            md={6}
            spacing={0}
            xs={12}
          >
            <div className={bem("image")}>
              <div className={bem("image__placeholder")}>
                <NextImage
                  fill
                  priority
                  alt="Phone"
                  aria-label="app-screenshot"
                  className={bem("image__placeholder__image")}
                  loading="eager"
                  src={(Phone as any)[theme.palette.mode ?? "light"]}
                />
                {screenshot && (
                  <div className={bem("image__screenshot__holder")}>
                    {renderScreenshot()}
                  </div>
                )}
              </div>
            </div>
          </GridRow>
        </Grid>
      </div>
    </div>
  )
}
