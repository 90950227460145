"use client"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import isEqual from "lodash-es/isEqual"
import isNull from "lodash-es/isNull"
import { FC, useEffect, useCallback, useState } from "react"

import { Animated } from "../animated"
import { ButtonColor } from "../button"
import { Grid, GridRow } from "../grid"
import { Headline } from "../headline"
import { NextImage, NextImageProps } from "../image"
import { RichText } from "../rich-text"

import { TeaserFeature, TeaserFeatureProps } from "./TeaserFeature"

import styles from "./TeaserFeatures.module.scss"

import { create } from "@/helpers/bem"

import FeatureTeaserPhone from "@/public/images/feature_teaser_phone.webp"

const bem = create(styles, "TeaserFeatures")

export type TeaserVariant = ButtonColor

export type TeaserFeaturesScreenshot = NextImageProps

export type TeaserFeaturesProps = {
  className?: string
  features?: TeaserFeatureProps[]
  screenshot: TeaserFeaturesScreenshot
}

export const TeaserFeatures: FC<TeaserFeaturesProps> = ({
  className,
  features,
  screenshot,
}) => {
  const theme = useTheme()
  const isDark = isEqual(theme.palette.mode, "dark")
  const screenSmallerThanSm = useMediaQuery(theme.breakpoints.down("sm"))
  const screenSmallerThanMd = useMediaQuery(theme.breakpoints.down("md"))
  const [screenshotOverlayIndex, setScreenshotOverlayIndex] = useState<
    number | null
  >(0)

  useEffect(() => {
    if (screenSmallerThanMd) {
      setTimeout(() => {
        setScreenshotOverlayIndex(null)
      }, 2500)
    }
  }, [screenSmallerThanMd])

  const renderScreenshot = useCallback(
    () => <NextImage {...screenshot} fill />,
    [screenshot],
  )

  const handleChangeScreenshotOverlay = useCallback(
    (index: number) => {
      if (isEqual(index, screenshotOverlayIndex)) {
        setScreenshotOverlayIndex(null)
        return
      }
      setScreenshotOverlayIndex(index)
    },
    [screenshotOverlayIndex],
  )

  const renderScreenshotOverlay = useCallback(() => {
    if (isNull(screenshotOverlayIndex)) {
      return null
    }
    const el = features?.[screenshotOverlayIndex]
    return (
      <Animated
        speed="fast"
        className={bem("image__screenshot__overlay", {
          "is-dark": isDark,
        })}
      >
        <Headline
          size={screenSmallerThanSm ? "sm" : "md"}
          type="h3"
          variant={el?.title?.variant ?? el?.variant ?? "primary"}
        >
          {el?.title?.content}
        </Headline>
        {el?.content && (
          <RichText
            className={bem("image__screenshot__overlay__content", {
              "is-dark": isDark,
            })}
          >
            {el?.content}
          </RichText>
        )}
      </Animated>
    )
  }, [isDark, features, screenshotOverlayIndex, screenSmallerThanSm])
  return (
    <div className={bem(undefined, undefined, className)}>
      <Grid className={bem("features")} xs={4}>
        {features?.slice(0, 3)?.map((feature, i) => {
          const id = feature?.id ?? `teaser-features-feature-${i}`
          return (
            <GridRow key={id} className={bem("features__feature")} xs={12}>
              <Animated
                animation="left-right"
                className={bem("features__feature__animation")}
                disabled={screenSmallerThanMd}
              >
                <TeaserFeature
                  id={id}
                  index={i}
                  onClick={
                    screenSmallerThanMd
                      ? () => handleChangeScreenshotOverlay(i)
                      : undefined
                  }
                  {...(feature as TeaserFeatureProps)}
                  variant={
                    !isNull(screenshotOverlayIndex) &&
                    !isEqual(screenshotOverlayIndex, i) &&
                    screenSmallerThanMd
                      ? "info"
                      : feature?.variant
                  }
                />
              </Animated>
            </GridRow>
          )
        })}
      </Grid>
      <Animated className={bem("image")}>
        <div className={bem("image__placeholder")}>
          <NextImage
            fill
            alt="Phone screenshot placeholder for app features"
            aria-label="app-screenshot-features"
            className={bem("image__placeholder__image")}
            src={FeatureTeaserPhone}
          />
        </div>
        {screenshot && (
          <div className={bem("image__screenshot__holder")}>
            {renderScreenshot()}
          </div>
        )}
        {!isNull(screenshotOverlayIndex) && screenSmallerThanMd
          ? renderScreenshotOverlay()
          : null}
      </Animated>
      <Grid className={bem("features")} xs={4}>
        {features?.slice(3)?.map((feature, i) => {
          const id = feature?.id ?? `teaser-features-feature-${i}`
          return (
            <GridRow
              key={id}
              xs={12}
              className={bem("features__feature", {
                "is-right": true,
              })}
            >
              <Animated
                animation="right-left"
                className={bem("features__feature__animation")}
                disabled={screenSmallerThanMd}
              >
                <TeaserFeature
                  reverse
                  id={id}
                  index={i}
                  onClick={
                    screenSmallerThanMd
                      ? () => handleChangeScreenshotOverlay(i + 3)
                      : undefined
                  }
                  {...(feature as TeaserFeatureProps)}
                  variant={
                    !isNull(screenshotOverlayIndex) &&
                    !isEqual(screenshotOverlayIndex, i + 3) &&
                    screenSmallerThanMd
                      ? "info"
                      : feature?.variant
                  }
                />
              </Animated>
            </GridRow>
          )
        })}
      </Grid>
    </div>
  )
}

TeaserFeatures.displayName = "TeaserFeatures"
