"use client"
export const getScreenshotAttr = (
  screenLargerSm?: boolean,
  screenLargerMd?: boolean,
  screenLargerLg?: boolean,
  screenLargerXl?: boolean,
) => {
  let sumOfVisibleItems = 1
  let size = {
    width: 200,
    height: 430,
  }
  if (screenLargerSm) {
    sumOfVisibleItems = 1
    size = {
      width: 240,
      height: 520,
    }
  }
  if (screenLargerMd) {
    sumOfVisibleItems = 3
    size = {
      width: 220,
      height: 470,
    }
  }
  if (screenLargerLg) {
    sumOfVisibleItems = 4
    size = {
      width: 290,
      height: 625,
    }
  }
  if (screenLargerXl) {
    sumOfVisibleItems = 5
  }
  return {
    sumOfVisibleItems,
    size,
  }
}
