"use client"
import MatAccordion, {
  AccordionProps as MatAccordionProps,
} from "@mui/material/Accordion"
import AccordionActions from "@mui/material/AccordionActions"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"

import { useTheme } from "@mui/material/styles"
import map from "lodash-es/map"
import { ReactNode, SyntheticEvent } from "react"

import { Animated } from "../../common/animated"
import { Button, ButtonProps } from "../../common/button"
import { Headline, HeadlineProps } from "../../common/headline"
import { Icon, IconProps } from "../../common/icon"

import styles from "./Accordion.module.scss"

import { create } from "@/helpers/bem"
import { Schema } from "@/types/schema"
import { Variants } from "@/types/variants"

const bem = create(styles, "Accordion")

export type AccordionAction = ButtonProps

export type AccordionItem = {
  title: string
  titleOptions?: HeadlineProps
  renderHeader?: () => ReactNode
  renderContent: () => ReactNode
  actions?: AccordionAction[]
} & Omit<MatAccordionProps, "children">

export type AccordionProps = {
  id: string
  expanded: number | null
  className?: string
  variant?: Variants
  titleOptions?: HeadlineProps
  iconProps?: IconProps
  items: AccordionItem[]
  schema?: Schema
  onChange: (index: number, event?: SyntheticEvent, expanded?: boolean) => void
}

export const Accordion = ({
  id,
  expanded,
  className,
  variant = "primary",
  titleOptions,
  iconProps,
  items,
  schema = {},
  ...props
}: AccordionProps) => {
  const theme = useTheme()
  return (
    <div className={bem(undefined, undefined, className)}>
      {map(items, (item, a) => {
        const {
          className,
          title,
          renderHeader,
          renderContent,
          actions,
          ...rest
        } = item
        const accId = `${id}-${a}`
        const isExpanded = `${id}-${expanded}` === accId
        return (
          <MatAccordion
            key={accId}
            sx={{
              borderRadius: 0,
              overflow: "hidden",
              boxShadow: theme.shadows[3],
              "&:first-of-type": {
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
              "&:last-of-type": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              },
              "& .MuiAccordion-region": {
                height: isExpanded ? "auto" : 0,
              },
              "& .MuiAccordionSummary-content": {
                margin: "7px 0",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "7px 0",
              },
              "& .MuiAccordionDetails-root": {
                opacity: isExpanded ? 1 : 0,
                height: isExpanded ? "auto" : 0,
                padding: "0px 32px 16px",
                color: theme.palette?.inherit.main,
                backgroundColor: theme.palette?.backgroundWhite.main,
              },
              "& .MuiAccordionSummary-root": {
                backgroundColor: theme.palette?.backgroundWhite.main,
              },
            }}
            {...rest}
            {...schema}
            className={bem(undefined, undefined, className)}
            content=""
            expanded={isExpanded}
            onChange={(e, open) => props.onChange(a, e, open)}
          >
            <AccordionSummary
              aria-controls={`${accId}-content`}
              id={`${accId}-header`}
              expandIcon={
                <Icon
                  name={isExpanded ? "Minus" : "Plus"}
                  size="sm"
                  color={
                    isExpanded
                      ? theme.palette?.inherit?.main
                      : theme.palette?.[variant]?.main
                  }
                  {...iconProps}
                />
              }
            >
              {renderHeader?.() ?? (
                <Headline
                  size="sm"
                  type="h3"
                  variant="inherit"
                  {...(item?.titleOptions ?? titleOptions)}
                >
                  {title}
                </Headline>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Animated className={bem("content")}>{renderContent()}</Animated>
            </AccordionDetails>
            {actions && actions?.length > 0 && (
              <AccordionActions>
                {map(actions, (action, b) => (
                  <Button key={`${accId}-action-${b}`} {...action} />
                ))}
              </AccordionActions>
            )}
          </MatAccordion>
        )
      })}
    </div>
  )
}
