"use client"
import map from "lodash-es/map"
import type { FC } from "react"
import { useState } from "react"

import styles from "./FAQ.module.scss"

import type { AccordionItem } from "@/components/common/accordion"
import { Accordion } from "@/components/common/accordion"
import { RichText } from "@/components/common/rich-text"

import { create } from "@/helpers/bem"

import type { ComponentSectionsFaqItem } from "@/types/generated/strapi/graphql"

const bem = create(styles, "FAQ")

export type FAQProps = {
  id: string
  faqs: ComponentSectionsFaqItem[]
}

export const FAQ: FC<FAQProps> = ({ id, faqs }) => {
  const [expanded, setExpanded] = useState<number | null>(null)
  const items = map(
    faqs,
    (faq) =>
      ({
        title: faq.question,
        renderContent: () => <RichText>{faq.answer}</RichText>,
      }) as AccordionItem,
  )
  return (
    <Accordion
      className={bem()}
      expanded={expanded}
      id={id}
      items={items}
      onChange={(index) => setExpanded(index)}
    />
  )
}
