"use client"
import { useTheme } from "@mui/material/styles"

import { ContentContainer, ContentContainerSpacing } from "../ContentContainer"
import { Suspense } from "../Suspense"

import type { IconName } from "@/components/common/icon"
import {
  ImageText as CommonImageText,
  ImageTextAnimation,
} from "@/components/common/image-text"

import { resolveDarkModeAsset } from "@/helpers/theme"
import type { ComponentSectionsTextImage } from "@/types/generated/strapi/graphql"

export type ImageTextProps = ComponentSectionsTextImage

export const ImageText = ({
  spacing,
  reverse,
  animatedBorder,
  title,
  titleOptions,
  subTitle,
  subTitleOptions,
  content,
  animation,
  image,
  imageDark,
  button,
}: ImageTextProps) => {
  const theme = useTheme()
  let animatedBorderDirection
  switch (animatedBorder) {
    case "bottom_to_top":
      animatedBorderDirection = "bottom-to-top"
      break
    case "top_to_bottom":
      animatedBorderDirection = "bottom-to-top"
      break
  }
  return (
    <ContentContainer spacing={spacing as ContentContainerSpacing}>
      <Suspense>
        <CommonImageText
          animation={animation as ImageTextAnimation}
          content={content ?? undefined}
          reverse={reverse as boolean | undefined}
          animatedBorder={
            animatedBorderDirection && {
              direction: animatedBorderDirection,
            }
          }
          button={
            button
              ? {
                  ...button,
                  "aria-label": button?.label,
                  title: button?.label ?? "",
                  iconProps: button?.icon
                    ? {
                        name: button.icon as IconName,
                        size: "sm",
                      }
                    : undefined,
                }
              : undefined
          }
          image={{
            src: resolveDarkModeAsset(theme, image, imageDark) ?? "",
            alt: image?.data?.attributes?.alternativeText ?? "",
          }}
          subTitle={
            subTitle
              ? {
                  content: subTitle,
                  ...subTitleOptions,
                }
              : undefined
          }
          title={{
            content: title,
            ...titleOptions,
          }}
        />
      </Suspense>
    </ContentContainer>
  )
}
