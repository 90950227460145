"use client"
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton"
import { ReactNode, Suspense as ReactSuspense } from "react"

import styles from "./Suspense.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Suspense")

export type SuspenseProps = {
  children: ReactNode
} & SkeletonProps

export const Suspense = ({ className, children, ...props }: SuspenseProps) => (
  <ReactSuspense
    fallback={
      <Skeleton
        variant="rectangular"
        {...props}
        className={bem(undefined, undefined, className)}
      />
    }
  >
    {children}
  </ReactSuspense>
)
