"use client"
import { useTheme } from "@mui/material/styles"

import {
  ContentContainer,
  ContentContainerBackgroundStyle,
} from "../ContentContainer"
import { Suspense } from "../Suspense"

import styles from "./TeaserRow.module.scss"

import { Animated } from "@/components/common/animated"
import { Grid, GridRow } from "@/components/common/grid"
import { Headline } from "@/components/common/headline"
import { Teaser, TeaserAnimation } from "@/components/common/teaser"

import { create } from "@/helpers/bem"
import { resolveDarkModeAsset } from "@/helpers/theme"
import {
  TeaserRowFragmentFragment,
  UploadFileEntityResponse,
} from "@/types/generated/strapi/graphql"

const bem = create(styles, "TeaserRow")

export type TeaserRowProps = TeaserRowFragmentFragment

export const TeaserRow = ({
  spacing,
  backgroundStyle,
  teaserRowTitle,
  titleOptions,
  subTitle,
  subTitleOptions,
  teasers,
}: TeaserRowProps) => {
  const theme = useTheme()
  return (
    <ContentContainer
      backgroundStyle={backgroundStyle as ContentContainerBackgroundStyle}
    >
      <div className={bem("header")}>
        {subTitle && (
          <Headline
            highlight
            className={bem("sub_headline", undefined)}
            size="sm"
            type="h3"
            variant="primary"
            {...subTitleOptions}
          >
            {subTitle}
          </Headline>
        )}
        {teaserRowTitle && (
          <Headline
            className={bem("headline", undefined)}
            size="lg"
            type="h2"
            {...titleOptions}
          >
            {teaserRowTitle}
          </Headline>
        )}
      </div>
      <Grid>
        {teasers.map((teaser, index) => (
          <Suspense key={`teaser-${index}`}>
            <GridRow
              spacing={spacing ?? 2}
              {...(teaser?.gridOptions as any)}
              md={teaser?.gridOptions?.md ?? 4}
              xs={teaser?.gridOptions?.xs ?? 12}
            >
              <Animated
                animation="bottom-top"
                className={bem("teaser__wrapper")}
              >
                <Teaser
                  animation={teaser?.animation as TeaserAnimation}
                  className={bem("teaser")}
                  content={teaser?.content ?? ""}
                  image={
                    teaser?.image
                      ? {
                          src:
                            resolveDarkModeAsset(
                              theme,
                              teaser?.image as UploadFileEntityResponse,
                              teaser?.imageDark as UploadFileEntityResponse,
                            ) ?? "",
                          alt:
                            teaser?.image?.data?.attributes?.alternativeText ??
                            "",
                        }
                      : undefined
                  }
                  title={{
                    content: teaser?.title ?? "",
                    ...teaser?.titleOptions,
                  }}
                />
              </Animated>
            </GridRow>
          </Suspense>
        ))}
      </Grid>
    </ContentContainer>
  )
}
