"use client"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import map from "lodash-es/map"
import { FC, useState } from "react"

import { Image } from "../Image"

import styles from "./FeatureCampaignContent.module.scss"

import { Animated } from "@/components/common/animated"
import { Card } from "@/components/common/card"
import { Grid, GridRow } from "@/components/common/grid"
import { Headline } from "@/components/common/headline"
import { Icon } from "@/components/common/icon"
import type { IconName, IconProps } from "@/components/common/icon"
import { Lottie } from "@/components/common/lottie"
import type { LottieAnimation } from "@/components/common/lottie"
import { Map, MapMarker } from "@/components/common/map"
import { RichText } from "@/components/common/rich-text"

import { create } from "@/helpers/bem"

const bem = create(styles, "FeatureCampaignContent")

import type {
  Featurecampaign,
  ComponentSharedHeadline,
  ComponentSharedMapCoordinates,
  UploadFileEntityResponse,
  FeaturecampaignComponentsDynamicZone,
} from "@/types/generated/strapi/graphql"

export type FeatureCampaignContentComponent =
  FeaturecampaignComponentsDynamicZone

export type FeatureCampaignContentHeadlineOptions = ComponentSharedHeadline & {
  className?: string
}

export type FeatureCampaignContentProps = Omit<
  Featurecampaign,
  "titleOptions" | "subTitleOptions"
> & {
  isFirst?: boolean
  titleOptions?: FeatureCampaignContentHeadlineOptions
  subTitleOptions?: FeatureCampaignContentHeadlineOptions
  iconProps?: Omit<IconProps, "name">
}

export const FeatureCampaignContent: FC<FeatureCampaignContentProps> = ({
  isFirst,
  animation,
  title,
  titleOptions,
  subTitle,
  subTitleOptions,
  content,
  icon,
  iconProps,
  components,
}) => {
  const theme = useTheme()
  const mode = theme?.palette?.mode
  const modifier = {
    [mode]: isEqual(mode, "dark"),
  }
  const [lineTopVisible, setLineTopVisible] = useState(false)
  const [lineBottomVisible, setLineBottomVisible] = useState(false)
  const component = components?.[0] as FeatureCampaignContentComponent
  const isQA = "qa" in component
  return (
    <div
      className={bem(undefined, {
        ...modifier,
        "is-first": !!isFirst,
      })}
    >
      {!isFirst && (
        <Grid>
          <GridRow sm={2} xs={3}>
            <div className={bem("animation__line__wrapper")}>
              <Animated
                className={bem("animation__line", {
                  [isQA
                    ? `top-to-bottom--${mode}--secondary`
                    : `top-to-bottom--${mode}`]: true,
                  "top-to-bottom-is-visible": lineTopVisible,
                })}
                onAnimate={(visible) => setLineTopVisible(visible)}
              />
            </div>
          </GridRow>
        </Grid>
      )}
      <Grid className={bem("campaign__header")}>
        <GridRow className={bem("animation__wrapper")} sm={2} xs={3}>
          <Lottie
            animationName={animation as LottieAnimation}
            className={bem("animation")}
            containerClassName={bem("animation__container")}
            variant={isQA ? "secondary" : undefined}
          />
          <Animated
            className={bem("animation__line", {
              "is-visible": lineBottomVisible,
              [isQA ? `${mode}--secondary` : mode]: true,
            })}
            onAnimate={(visible) => setLineBottomVisible(visible)}
          />
        </GridRow>
        <GridRow md={6} sm={10} xs={9}>
          <Animated animation="left-right">
            {subTitle && (
              <Headline
                highlight
                size="xl"
                type="h3"
                variant="inherit"
                {...subTitleOptions}
                className={bem(
                  "sub__headline",
                  undefined,
                  subTitleOptions?.className,
                )}
              >
                {subTitle}
              </Headline>
            )}
            {title && (
              <Headline
                highlight
                size="xxl"
                type="h2"
                variant={isQA ? "secondary" : "primary"}
                {...titleOptions}
                className={bem("headline", undefined, titleOptions?.className)}
              >
                {title}
              </Headline>
            )}
            {content && (
              <RichText className={bem("content", modifier)}>
                {content}
              </RichText>
            )}
          </Animated>
        </GridRow>
        <GridRow className={bem("icon__container")} md={12} xs={1}>
          {icon && (
            <Animated className={bem("icon__wrapper")}>
              <Icon
                className={bem("icon")}
                color={theme.palette.lightGray.main}
                {...iconProps}
                name={icon.icon as IconName}
              />
            </Animated>
          )}
        </GridRow>
      </Grid>
      <div className={bem("campaign__content")}>
        {component && "center" in component && (
          <Card
            enableShadow
            className={bem("map")}
            customAnimation="right-left"
          >
            <Map
              center={component?.center}
              zoom={component?.zoom ?? 5}
              marker={
                map(
                  component?.marker as ComponentSharedMapCoordinates[],
                  (marker: ComponentSharedMapCoordinates) => ({
                    position: {
                      lat: marker?.lat,
                      lng: marker?.lng,
                    },
                  }),
                ) as MapMarker[]
              }
            />
          </Card>
        )}
        {component && "image" in component && (
          <Animated animation="right-left">
            {}
            <Image
              className={bem("campaign__image")}
              dark={component?.imageDark as UploadFileEntityResponse}
              light={component?.image as UploadFileEntityResponse}
            />
          </Animated>
        )}
        {component && isQA && (
          <Card
            enableShadow
            className={bem("campaign__qa")}
            customAnimation="right-left"
          >
            {map(component?.qa, (qa, index) => (
              <div key={`qa-${index}`} className={bem("campaign__qa__item")}>
                <Headline
                  animated
                  highlight
                  size="lg"
                  type="h3"
                  variant="secondary"
                  animationProps={{
                    speed: 20,
                  }}
                  {...qa?.questionOptions}
                  className={bem(
                    "headline",
                    undefined,
                    titleOptions?.className,
                  )}
                >
                  {qa?.question}
                </Headline>
                <Animated delay={500}>
                  <RichText className={bem("content", modifier)}>
                    {qa?.answer}
                  </RichText>
                </Animated>
              </div>
            ))}
          </Card>
        )}
      </div>
    </div>
  )
}
