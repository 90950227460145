import { FC, ReactNode } from "react"

import { NextImage } from "../image"
import { Link } from "../link"

import styles from "./AppStoreButtons.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "AppStoreButtons")

import AppleButtonDE from "@/public/images/apple_button_download_de.webp"
import AppleButtonEN from "@/public/images/apple_button_download_en.webp"
import PlayStoreButtonDE from "@/public/images/play_button_download_de.webp"
import PlayStoreButtonEN from "@/public/images/play_button_download_en.webp"

export type AppStoreButtonsLocale = "en" | "de"

export type AppStoreButtonType = "ios" | "android"

export type AppStoreButtonsProps = {
  locale?: AppStoreButtonsLocale
  className?: string
  appleButtonUrl?: string | null
  playButtonUrl?: string | null
  onClick?: (type: AppStoreButtonType) => void
}

export const AppStoreButtons: FC<AppStoreButtonsProps> = ({
  locale = "en",
  className,
  appleButtonUrl,
  playButtonUrl,
  onClick,
}) => {
  const renderLink = (
    type: AppStoreButtonType,
    href?: string,
    children?: ReactNode,
  ) => (
    <Link
      aria-label="Download app now"
      className={bem("link")}
      href={href ?? "/"}
      onClick={() => onClick?.(type)}
    >
      {children}
    </Link>
  )
  const button = {
    de: {
      apple: AppleButtonDE,
      playStore: PlayStoreButtonDE,
    },
    en: {
      apple: AppleButtonEN,
      playStore: PlayStoreButtonEN,
    },
  }
  return (
    <div className={bem(undefined, undefined, className)}>
      {appleButtonUrl &&
        renderLink(
          "ios",
          appleButtonUrl,
          <NextImage
            fill
            priority
            alt="Download on AppStore"
            aria-label="Download on AppStore"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNctmRJPQAGVQJv2Bo0IgAAAABJRU5ErkJggg=="
            className={bem("link__image")}
            placeholder="blur"
            role="button"
            src={button?.[locale]?.apple ?? button.en.apple}
          />,
        )}
      {playButtonUrl &&
        renderLink(
          "android",
          playButtonUrl,
          <NextImage
            fill
            priority
            alt="Download on PlayStore"
            aria-label="Download on PlayStore"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNctmRJPQAGVQJv2Bo0IgAAAABJRU5ErkJggg=="
            className={bem("link__image")}
            placeholder="blur"
            role="button"
            src={button?.[locale]?.playStore ?? button.en.playStore}
          />,
        )}
    </div>
  )
}
