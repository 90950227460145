import { useTheme } from "@mui/material/styles"
import { FC, memo } from "react"

import { Card } from "../card"
import { Headline, HeadlineProps } from "../headline"
import { Icon, IconProps } from "../icon"
import { NextImage, NextImageProps } from "../image"
import { LinkProps } from "../link"
import { Lottie, LottieAnimation } from "../lottie"
import { RichText } from "../rich-text"

import styles from "./Teaser.module.scss"

import { create } from "@/helpers/bem"
import { Variants } from "@/types/variants"

const bem = create(styles, "Teaser")

export type TeaserVariant = Variants

export type TeaserHeadline = {
  content: string
} & Omit<HeadlineProps, "children">

export type TeaserImage = NextImageProps

export type TeaserAnimation = LottieAnimation

export type TeaserRedirect = LinkProps & {
  label?: string
  icon?: IconProps
}

export type TeaserAlign = "left" | "center" | "right"

export type TeaserProps = {
  className?: string
  variant?: TeaserVariant
  align?: TeaserAlign
  lineClamp?: boolean
  title: TeaserHeadline
  content?: string
  image?: TeaserImage
  animation?: TeaserAnimation
  onClick?: () => void
  redirect?: TeaserRedirect
}

export const Teaser: FC<TeaserProps> = memo(
  ({
    className,
    variant = "primary",
    animation,
    lineClamp,
    align,
    image,
    title,
    content,
    onClick,
    redirect,
    ...props
  }) => {
    const theme = useTheme()
    return (
      <Card
        variant={variant}
        {...props}
        className={bem(undefined, undefined, className)}
        contentClassName={bem("card")}
        redirect={redirect}
        onClick={onClick}
      >
        {animation && (
          <Lottie animationName={animation} className={bem("animation")} />
        )}
        {image && (
          <div className={bem("image__wrapper")}>
            <NextImage
              {...(image as NextImageProps)}
              fill
              captionClassName={bem("image__caption")}
              className={bem("image")}
              containerClassName={bem("image")}
            />
          </div>
        )}
        <div className={bem("card__content")}>
          <Headline
            align={align ?? "center"}
            size="md"
            type="h3"
            {...title}
            variant={title?.variant ?? variant}
            className={bem(
              "headline",
              {
                "has-lineClamp": !!lineClamp,
              },
              title?.className,
            )}
          >
            {title?.content}
          </Headline>
          {content && (
            <RichText
              className={bem("content", {
                "has-lineClamp": !!lineClamp,
                [`align-${align}`]: !!align,
              })}
            >
              {content}
            </RichText>
          )}
          {redirect?.label && (
            <span className={bem("link")}>
              <Icon
                color={theme.palette?.[variant].main}
                name="ArrowRightCircle"
                size="xs"
                {...redirect?.icon}
                className={bem(
                  "link__icon",
                  undefined,
                  redirect?.icon?.className,
                )}
              />
              {redirect.label}
            </span>
          )}
        </div>
      </Card>
    )
  },
)

Teaser.displayName = "Teaser"
