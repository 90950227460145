"use client"
import Skeleton from "@mui/material/Skeleton"
import { useTheme } from "@mui/material/styles"
import forEach from "lodash/forEach"
import isEqual from "lodash/isEqual"
import type { FC } from "react"
import { memo, useEffect, useCallback, useRef } from "react"

import { useGoogleMaps } from "@/hooks/useGoogleMaps"
import { themeChangeEmitter, onThemeChange } from "@/utils/themeChangeEmitter"

export type MapCenter = {
  lat: number
  lng: number
}

export type MapMarkerPosition = {
  lat: number
  lng: number
}

export type MapMarker = Omit<
  google.maps.marker.AdvancedMarkerElement,
  "position" | "map"
> & {
  position: MapMarkerPosition
}

export type MapProps = {
  mapId?: string
  center: MapCenter
  marker?: MapMarker[]
  zoom?: number
  heading?: number
  tilt?: number
}

export const Map: FC<MapProps> = memo(({ mapId, center, marker, zoom = 8 }) => {
  const mapRef = useRef<HTMLDivElement | null>(null)
  const mapInstanceRef = useRef<google.maps.Map | null>(null)
  const theme = useTheme()
  const isDark = isEqual(theme?.palette?.mode, "dark")

  const initMap = useCallback(() => {
    if (!mapRef?.current) return
    let options = {
      center,
      zoom,
      mapId: (mapId ?? isDark) ? "c3c70a1ea4c4b0b8" : "e5921eca3f70ade2",
      disableDefaultUI: true,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: true,
    } as google.maps.MapOptions
    const map = new google.maps.Map(mapRef.current as HTMLElement, options)
    mapInstanceRef.current = map
    if (!marker || marker?.length === 0) return
    forEach(marker, (place: MapMarker) => {
      if (!place?.position) return
      const content = document.createElement("img")
      content.src = "/images/mymatey-map-marker.webp"
      new google.maps.marker.AdvancedMarkerElement({
        map,
        content,
        ...place,
      })
    })
  }, [isDark, mapId, center, marker, zoom])

  const loaded = useGoogleMaps(isDark)

  useEffect(() => {
    if (!loaded || mapInstanceRef.current) return
    initMap()
  }, [loaded, initMap])

  useEffect(() => {
    onThemeChange(initMap)
    return () => {
      themeChangeEmitter.off("themeChange", initMap)
    }
  }, [initMap])

  if (!loaded) {
    return <Skeleton height={500} width="100%" />
  }
  return <div ref={mapRef} style={{ width: "100%", height: "500px" }} />
})

Map.displayName = "Map"
