"use client"
import map from "lodash-es/map"
import { usePathname } from "next/navigation"

import { ContentContainer } from "../ContentContainer"
import { Suspense } from "../Suspense"

import styles from "./VideoBanner.module.scss"

import { VideoBanner as CommonVideoBanner } from "@/components/common/video-banner"

import { resolveMediaUrl } from "@/helpers/api-helpers"
import { create } from "@/helpers/bem"
import { trackCTAAppDownload } from "@/helpers/tracking"
import { ComponentSectionsVideoBanner } from "@/types/generated/strapi/graphql"

export type VideoBannerProps = ComponentSectionsVideoBanner

const bem = create(styles, "VideoBanner")

export const VideoBanner = ({
  content,
  video = [],
  screenshot,
  appStoreUrl,
  playStoreUrl,
  ...props
}: VideoBannerProps) => {
  const path = usePathname()
  const screenshotAttr = screenshot?.data?.attributes
  return (
    <ContentContainer fullWidth className={bem()}>
      <Suspense>
        <CommonVideoBanner
          {...props}
          appleButtonUrl={appStoreUrl ?? ""}
          content={content ?? undefined}
          playButtonUrl={playStoreUrl ?? ""}
          // TODO: Better way for video implementation will be needed. Poster currently has cracks.
          // videoPosterSrc={resolveMediaUrl(poster?.data?.attributes?.url) ?? ""}
          screenshot={{
            src: resolveMediaUrl(screenshotAttr?.url) ?? "",
            alt: screenshotAttr?.alternativeText ?? "",
          }}
          videoSrc={map(video, (e) => {
            const attr = e?.src.data?.attributes
            return {
              key: `video-src-${e?.src?.data?.id}`,
              type: attr?.mime,
              src: resolveMediaUrl(attr?.url) ?? "",
            }
          })}
          onClickButton={(type) =>
            trackCTAAppDownload("video_banner", path, type)
          }
        />
      </Suspense>
    </ContentContainer>
  )
}
