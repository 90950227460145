"use client"
import { useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"

import {
  ContentContainer,
  ContentContainerBackgroundStyle,
} from "../ContentContainer"

import styles from "./TeaserFeatures.module.scss"

import { Headline } from "@/components/common/headline"
import { IconName } from "@/components/common/icon"
import {
  TeaserFeatures as CommonTeaserFeatures,
  TeaserFeatureVariant,
} from "@/components/common/teaser-features"

import { create } from "@/helpers/bem"
import { resolveDarkModeAsset } from "@/helpers/theme"
import {
  TeaserFeaturesFragmentFragment,
  UploadFileEntityResponse,
} from "@/types/generated/strapi/graphql"

const bem = create(styles, "TeaserFeatures")

export type TeaserFeaturesProps = TeaserFeaturesFragmentFragment

export const TeaserFeatures = ({
  backgroundStyle,
  teaserFeatureTitle,
  titleOptions,
  subTitle,
  subTitleOptions,
  screenshot,
  screenshotDark,
  features,
}: TeaserFeaturesProps) => {
  const theme = useTheme()
  const formatedFeatures = useMemo(
    () =>
      features?.map((feature, index) => ({
        ...feature,
        id: index?.toString(),
        title: {
          content: feature?.title ?? "",
          ...feature?.titleOptions,
        },
        content: feature?.content as string,
        variant: feature?.icon?.variant as TeaserFeatureVariant,
        icon: feature?.icon?.icon as IconName,
      })),
    [features],
  )
  return (
    <ContentContainer
      backgroundStyle={backgroundStyle as ContentContainerBackgroundStyle}
    >
      <div className={bem("header")}>
        {subTitle && (
          <Headline
            highlight
            align="center"
            className={bem("sub_headline", undefined)}
            size="sm"
            type="h3"
            variant="primary"
            {...subTitleOptions}
          >
            {subTitle}
          </Headline>
        )}
        {teaserFeatureTitle && (
          <Headline
            align="center"
            className={bem("headline", undefined)}
            size="lg"
            type="h2"
            {...titleOptions}
          >
            {teaserFeatureTitle}
          </Headline>
        )}
      </div>
      <CommonTeaserFeatures
        features={formatedFeatures ?? []}
        screenshot={{
          src:
            resolveDarkModeAsset(
              theme,
              screenshot as UploadFileEntityResponse,
              screenshotDark as UploadFileEntityResponse,
            ) ?? "",
          alt: screenshot?.data?.attributes?.alternativeText ?? "",
        }}
      />
    </ContentContainer>
  )
}
