"use client"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import map from "lodash-es/map"
import type { FC } from "react"
import { useEffect, useState } from "react"

import { Animated } from "../animated"
import { Carousel } from "../carousel"
import type { NextImageProps } from "../image"
import { NextImage } from "../image"

import { getScreenshotAttr } from "./Carousel.utils"
import styles from "./CarouselScreenshots.module.scss"

import { create } from "@/helpers/bem"
import { Variants } from "@/types/variants"

const bem = create(styles, "CarouselScreenshots")

export type CarouselScreenshotsVariants = Variants

export type CarouselScreenshot = NextImageProps

export type CarouselScreenshotsProps = {
  autoplay?: number
  variant?: CarouselScreenshotsVariants
  screenshots?: CarouselScreenshot[]
}

export const CarouselScreenshots: FC<CarouselScreenshotsProps> = ({
  variant,
  autoplay,
  screenshots = [],
}) => {
  const theme = useTheme()
  const screenLargerSm = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  })
  const screenLargerMd = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  })
  const screenLargerLg = useMediaQuery(theme.breakpoints.up("lg"), {
    noSsr: true,
  })
  const screenLargerXl = useMediaQuery(theme.breakpoints.up("xl"), {
    noSsr: true,
  })
  const [options, setOptions] = useState(() => getScreenshotAttr())
  const [key, setKey] = useState(
    () => `${options.size.width}-${options.size.height}`,
  )
  useEffect(() => {
    const newOptions = getScreenshotAttr(
      screenLargerSm,
      screenLargerMd,
      screenLargerLg,
      screenLargerXl,
    )
    setOptions(newOptions)
    setKey(`${newOptions.size.width}-${newOptions.size.height}`)
  }, [screenLargerSm, screenLargerMd, screenLargerLg, screenLargerXl])
  return (
    <Animated
      className={bem(undefined, {
        [theme.palette.mode]: true,
        "has-overlay": !!variant,
        [`${theme.palette.mode}--${variant}`]: !!variant,
      })}
    >
      <Carousel
        autoplay={autoplay ?? 5000}
        classNameItem={bem("screenshot__wrapper")}
        itemsToShow={options.sumOfVisibleItems}
      >
        {map(screenshots, (screenshot, i) => (
          <NextImage
            key={`carousel-screenshot-${i}-${key}`}
            className={bem("screenshot", {
              [`${theme.palette.mode}`]: true,
            })}
            {...screenshot}
            height={options.size.height}
            width={options.size.width}
          />
        ))}
      </Carousel>
    </Animated>
  )
}
