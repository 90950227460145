import { Loader } from "@googlemaps/js-api-loader"
import { useEffect, useState } from "react"

import Bugsnag from "@/config/bugsnag"

import { GOOGLE_MAPS_API_KEY } from "@/utils/enviroment"

export const useGoogleMaps = (reload?: boolean) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY ?? "",
      version: "weekly",
      libraries: ["maps"],
    })

    loader
      .importLibrary("marker")
      .then(() => {
        setLoaded(true)
      })
      .catch((err: any) => {
        Bugsnag.notify(err, (event) => {
          event.context = "useGoogleMaps-import-library"
        })
        console.error("Error loading Google Maps", err)
      })
  }, [reload])

  return loaded
}
