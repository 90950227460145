import { EventEmitter } from "events"

export const themeChangeEmitter = new EventEmitter()

export const onThemeChange = (callback: (isDark: boolean) => void) => {
  themeChangeEmitter.on("themeChange", callback)
}

export const emitThemeChange = (isDark: boolean) => {
  themeChangeEmitter.emit("themeChange", isDark)
}
