"use client"
import { useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"

import {
  ContentContainer,
  ContentContainerBackgroundStyle,
} from "../ContentContainer"

import styles from "./CarouselScreenshots.module.scss"

import {
  CarouselScreenshots as CommonCarouselScreenshots,
  CarouselScreenshot,
  CarouselScreenshotsVariants,
} from "@/components/common/carousel-screenshots"
import { Headline } from "@/components/common/headline"

import { create } from "@/helpers/bem"
import { resolveDarkModeAsset } from "@/helpers/theme"
import {
  CarouselScreenshotsFragmentFragment,
  UploadFileEntityResponse,
} from "@/types/generated/strapi/graphql"

const bem = create(styles, "CarouselScreenshots")

export type CarouselScreenshotsProps = CarouselScreenshotsFragmentFragment

export const CarouselScreenshots = ({
  backgroundStyle,
  carouselScreenshotsTitle,
  titleOptions,
  subTitle,
  subTitleOptions,
  carouselOptions,
  carouselScreenshots,
}: CarouselScreenshotsProps) => {
  const theme = useTheme()
  const formatedScreenshots = useMemo(
    () =>
      carouselScreenshots?.map((el) => ({
        src:
          resolveDarkModeAsset(
            theme,
            el?.screenshot as UploadFileEntityResponse,
            el?.screenshotDark as UploadFileEntityResponse,
          ) ?? "",
        alt: el?.screenshot?.data?.attributes?.alternativeText ?? "",
      })) as CarouselScreenshot[],
    [theme, carouselScreenshots],
  )
  return (
    <ContentContainer
      backgroundStyle={backgroundStyle as ContentContainerBackgroundStyle}
    >
      <div className={bem("header")}>
        {subTitle && (
          <Headline
            highlight
            align="center"
            className={bem("sub_headline", undefined)}
            size="sm"
            type="h3"
            variant="primary"
            {...subTitleOptions}
          >
            {subTitle}
          </Headline>
        )}
        {carouselScreenshotsTitle && (
          <Headline
            align="center"
            className={bem("headline", undefined)}
            size="lg"
            type="h2"
            {...titleOptions}
          >
            {carouselScreenshotsTitle}
          </Headline>
        )}
      </div>
      <CommonCarouselScreenshots
        autoplay={carouselOptions?.autoplay as number | undefined}
        screenshots={formatedScreenshots}
        variant={carouselOptions?.variant as CarouselScreenshotsVariants}
      />
    </ContentContainer>
  )
}
