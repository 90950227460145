"use client"
import { useTheme } from "@mui/material/styles"
import isEqual from "lodash-es/isEqual"
import { FC, memo } from "react"

import { Button, ButtonColor } from "../button"
import { Headline, HeadlineProps } from "../headline"
import { IconName } from "../icon"
import { RichText } from "../rich-text"

import styles from "./TeaserFeature.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "TeaserFeature")

export type TeaserFeatureVariant = ButtonColor

export type TeaserFeatureHeadline = {
  content: string
} & Omit<HeadlineProps, "children">

export type TeaserFeatureProps = {
  id?: string
  index?: number
  className?: string
  reverse?: boolean
  variant?: TeaserFeatureVariant
  title: TeaserFeatureHeadline
  content?: string
  icon?: IconName
  onClick?: () => void
}

export const TeaserFeature: FC<TeaserFeatureProps> = memo(
  ({
    id,
    index,
    className,
    reverse,
    variant = "primary",
    title,
    content,
    icon,
    onClick,
  }) => {
    const theme = useTheme()
    const isDark = isEqual(theme.palette.mode, "dark")
    return (
      <div
        id={id}
        className={bem(
          undefined,
          {
            "is-reverse": !!reverse,
            [`is-${index}`]: !reverse && typeof index === "number",
            [`is-reverse--is-${index}`]: !!reverse && typeof index === "number",
          },
          className,
        )}
      >
        <div
          className={bem("content", {
            "is-reverse": !!reverse,
          })}
        >
          <Headline
            align={!reverse ? "right" : "left"}
            size="md"
            type="h3"
            variant={title?.variant}
            {...title}
          >
            {title?.content}
          </Headline>
          {content && (
            <RichText
              className={bem("text", {
                "is-reverse": !!reverse,
                "is-dark": isDark,
              })}
            >
              {content}
            </RichText>
          )}
        </div>
        <div>
          <Button
            aria-label="More about this feature"
            className={bem("button")}
            color={variant}
            iconProps={{
              name: icon,
              size: "md",
            }}
            onClick={onClick}
          />
        </div>
      </div>
    )
  },
)

TeaserFeature.displayName = "TeaserFeature"
